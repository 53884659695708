import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";

import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import { meUserDefinition } from "@/definitions/meDefinitions";
import { severityEnum } from "@/enums/styleVariantEnum";
import { CREATE_WEB_PUSH_DEVICE } from "@/mutations/pushNotificationMutation";
import { GET_WEB_PUSH_DEVICE_SIMPLE } from "@/queries/pushNotificationQueries";
import useMeQuery from "@/services/queryHooks/useMeQuery";
import { useValueRef } from "@/utils/hookUtils";
import * as pushNotificationUtils from "@/utils/pushNotificationUtils";

const PushNotificationManager = () => {
  const { onSetAppSnackbarProps } = useAppSnackbar();

  const { data: meQueryData } = useMeQuery({ objectShape: meUserDefinition });
  const agent = useMemo(() => meQueryData?.me || {}, [meQueryData]);

  const { data, loading: webPushDeviceLoading } = useQuery(
    GET_WEB_PUSH_DEVICE_SIMPLE,
    { variables: { agentId: agent.id } },
  );
  const { webPushDevices } = data || {};

  const [createWebPushDevice] = useMutation(CREATE_WEB_PUSH_DEVICE, {
    onError: ({ message }) => {
      onSetAppSnackbarProps({
        message,
        AlertProps: { severity: severityEnum.error },
      });
    },
  });

  const valueRefs = useValueRef({ webPushDevices, createWebPushDevice });

  /* Setup push notification subscription for the agent */
  useEffect(() => {
    if (isEmpty(agent)) return;
    if (webPushDeviceLoading) return;

    const isPushNotificationSupported =
      "serviceWorker" in navigator && "PushManager" in window;

    if (!isPushNotificationSupported) {
      console.error("Push notification isn't supported in this browser.");
      return;
    }

    (async () => {
      try {
        const permission = await Notification.requestPermission();
        const hasPermission = permission === "granted";

        if (!hasPermission) {
          alert("Please grant permission in order to use push notification");
          return;
        }

        await navigator.serviceWorker.register("/sw.js");

        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        const existingSubscription =
          await serviceWorkerRegistration.pushManager.getSubscription();

        const { webPushDevices, createWebPushDevice } = valueRefs.current;
        const isWebPushDeviceCreated = !isEmpty(webPushDevices);

        /* getSubscription() always resolves to either PushSubscription object or null value */
        const hasExistingSubscription = existingSubscription !== null;

        if (isWebPushDeviceCreated && hasExistingSubscription) return;

        const newSubscription =
          await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: pushNotificationUtils.getPublicVapidKey(),
          });

        createWebPushDevice({
          variables: {
            input: pushNotificationUtils.prepareCreateWebPushDeviceInput({
              agent,
              subscription: newSubscription,
            }),
          },
        });
      } catch (error) {
        console.error(`Failed to subscribe to push notification - ${error}`);

        Sentry.captureException(error);
      }
    })();
  }, [valueRefs, agent, webPushDeviceLoading]);

  return null;
};

export default PushNotificationManager;
